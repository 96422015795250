import React, { useState, useEffect } from "react";
import { Navbar } from "../components/Navbar";
import {
  chunkArray,
  convertToMapEmbed,
  generateMapsLink,
  getPlaces,
} from "../api/routing";
import * as UI from "../components/_Misc";
import { nearestNeighbor } from "../api/nearest_neighbor";
import { Handler, Route, Error } from "../Connection/System";

function Section_Hero() {
  return (
    <div className="flex w-full items-center justify-center flex-col gap-1 py-8 text-center px-1">
      <div className="text-2xl sm:text-[2.5rem] font-bold text-[#44986fff]">
        Intelligent Route Generator
      </div>
      <div className="text-md sm:text-lg text-black">
        Plan and optimize your journey with ease.
      </div>
    </div>
  );
}

function LegDiv(props) {
  let neighbor = nearestNeighbor(props.places);
  let staticMap = convertToMapEmbed(neighbor, props.index);

  neighbor = chunkArray(neighbor, 10)[props.index];

  let firstDest = "Your Location";
  if (props.index > 0) {
    firstDest = neighbor[0].name;
  }
  let lastDest = neighbor[neighbor.length - 1].name;
  return (
    <div className="flex flex-col bg-[#ECF0F1] shadow-md gap-2">
      <img src={staticMap} alt="Google Map" />
      <h1 className="text-2xl font-bold p-2 text-[#44986fff]">
        Leg {props.index + 1}
      </h1>
      <div className="flex gap-2 items-center p-2 font-semibold">
        <span>{firstDest}</span>
        <span className="text-3xl">
          <i className="bi bi-arrow-right text-[#3d74cdff]"></i>
        </span>
        <span>{lastDest}</span>
      </div>
      <UI.PieButton
        onClick={() => {
          window.open(props.map, "_blank");
        }}
        icon="car-front"
        text="Start"
        customstyle="w-[100%] lg:w-[100%]"
      />
    </div>
  );
}

export function RouteFinder() {
  const [zipCodes, setZipCodes] = useState([]);
  const [businessType, setBusinessType] = useState("");
  const [country, setCountry] = useState("Alaska");
  const [places, setPlaces] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [mapLinks, setMapLinks] = useState([]);

  const [fixedPlacesLength, setfixedPlacesLength] = useState(0);

  const [zipInputAmount, setZipInputAmount] = useState(1);

  const handleZipCodeChange = (event, index) => {
    const newZipCodes = [...zipCodes];
    newZipCodes[index] = event.target.value;
    setZipCodes(newZipCodes);
  };

  const handleBusinessTypeChange = (event) => {
    setBusinessType(event.target.value);
  };

  const handleAddZipCodeInput = () => {
    if (zipInputAmount < 4) {
      setZipInputAmount(zipInputAmount + 1);
      setZipCodes([...zipCodes, ""]);
    }
  };

  const handleSearch = async () => {
    if (zipCodes.length < 1 || businessType.length < 1 || country.length < 1) {
      Error.error("Please fill all fields.");
      return;
    }
    document.querySelector("#genButton").outerHTML =
      '<div class="spinner"></div>';
    const validZips = zipCodes.filter((zip) => zip.trim() !== "");
    let data = await Route.getPlaces(country, validZips, businessType);
    if (data == false) {
      return;
    }
    setPlaces(data);
    setfixedPlacesLength(data.length);
    setShowResults(true);
  };

  const handleDelete = (index) => {
    const newPlaces = [...places];
    newPlaces.splice(index, 1);
    setPlaces(newPlaces);
  };

  const handleGo = async () => {
    if (places.length === 0) {
      console.log("No places to create a route for.");
      return;
    }
    if (places.length > 20) {
      Error.error("Maximum of 20 places allowed.");
      return;
    }
    let data = await Route.links(places);
    if (data == false) {
      return;
    }
    setMapLinks(data);
  };

  useEffect(() => {
    async function check() {
      let d = await Handler.verify();
      if (d == false) {
        alert("Create an account to use this tool.");
        window.location.href = "/";
      }
    }
    check();
  }, []);

  useEffect(() => {
    document.body.style.overflowY = "auto";
  });

  const [currentZIP, setCurrentZIP] = useState(-1);
  const [downloadedZips, setDownloadedZips] = useState([]);

  return (
    <>
      <Navbar />
      {currentZIP >= 0 && (
        <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] cursor-default flex flex-col items-center justify-center bg-white py-12 px-4 rounded-md shadow-xl gap-8 min-w-[270px] z-30">
          <div
            className="absolute top-0 right-0 cursor-pointer p-2 rounded-full flex items-center justify-center"
            onClick={() => {
              setDownloadedZips([]);
              setCurrentZIP(-1);
            }}
          >
            <UI.Icon icon="x-lg" />
          </div>
          <div className="w-full flex flex-col gap-6">
            <UI.PieLabelInput label="City Name" id="cityName" />
            <UI.PieButton
              icon="geo-alt-fill"
              text="Find ZIP Codes"
              customstyle="w-full lg:w-full"
              onClick={async () => {
                let value = document.querySelector("#cityName").value;
                if (value == "") {
                  Error.error("Please enter a city name.");
                  return;
                }
                let data = await Route.getZipCodes(value, country);
                if (data == false) {
                  return;
                }
                setDownloadedZips(data);
              }}
            />
          </div>
          {downloadedZips.length > 0 && (
            <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-2 px-1 py-5 max-h-[50vh] overflow-y-auto overflow-x-hidden">
              {downloadedZips.map((zip, index) => (
                <div
                  key={index}
                  className="flex w-full bg-[#ECF0F1] text-[#012047ff] shadow-md font-semibold rounded-md p-2 justify-between items-center"
                >
                  <span>{zip}</span>
                  <span
                    className="cursor-pointer hover:translate-y-[-5%] duration-200 text-2xl"
                    onClick={() => {
                      let zzz = [...zipCodes];
                      zzz[currentZIP] = zip;

                      setZipCodes(zzz);

                      setDownloadedZips([]);
                      setCurrentZIP(-1);
                    }}
                  >
                    <UI.Icon icon="plus-lg" />
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="min-h-[75vh] flex items-center justify-center flex-col">
        {!showResults ? (
          <>
            <Section_Hero />
            <div className="rounded-md shadow-md p-3 flex flex-col bg-[#ECF0F1]">
              <div className="flex justify-center my-5">
                <UI.PieSelect
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  options={[
                    { label: "Alaska", value: "Alaska" },
                    { label: "Alabama", value: "Alabama" },
                    { label: "Arkansas", value: "Arkansas" },
                    { label: "Arizona", value: "Arizona" },
                    { label: "California", value: "California" },
                    { label: "Colorado", value: "Colorado" },
                    { label: "Connecticut", value: "Connecticut" },
                    {
                      label: "District of Columbia",
                      value: "District of Columbia",
                    },
                    { label: "Delaware", value: "Delaware" },
                    { label: "Florida", value: "Florida" },
                    { label: "Georgia", value: "Georgia" },
                    { label: "Hawaii", value: "Hawaii" },
                    { label: "Iowa", value: "Iowa" },
                    { label: "Idaho", value: "Idaho" },
                    { label: "Illinois", value: "Illinois" },
                    { label: "Indiana", value: "Indiana" },
                    { label: "Kansas", value: "Kansas" },
                    { label: "Kentucky", value: "Kentucky" },
                    { label: "Louisiana", value: "Louisiana" },
                    { label: "Massachusetts", value: "Massachusetts" },
                    { label: "Maryland", value: "Maryland" },
                    { label: "Maine", value: "Maine" },
                    { label: "Michigan", value: "Michigan" },
                    { label: "Minnesota", value: "Minnesota" },
                    { label: "Missouri", value: "Missouri" },
                    { label: "Mississippi", value: "Mississippi" },
                    { label: "Montana", value: "Montana" },
                    { label: "North Carolina", value: "North Carolina" },
                    { label: "North Dakota", value: "North Dakota" },
                    { label: "Nebraska", value: "Nebraska" },
                    { label: "New Hampshire", value: "New Hampshire" },
                    { label: "New Jersey", value: "New Jersey" },
                    { label: "New Mexico", value: "New Mexico" },
                    { label: "Nevada", value: "Nevada" },
                    { label: "New York", value: "New York" },
                    { label: "Ohio", value: "Ohio" },
                    { label: "Oklahoma", value: "Oklahoma" },
                    { label: "Oregon", value: "Oregon" },
                    { label: "Pennsylvania", value: "Pennsylvania" },
                    { label: "Rhode Island", value: "Rhode Island" },
                    { label: "South Carolina", value: "South Carolina" },
                    { label: "South Dakota", value: "South Dakota" },
                    { label: "Tennessee", value: "Tennessee" },
                    { label: "Texas", value: "Texas" },
                    { label: "Utah", value: "Utah" },
                    { label: "Virginia", value: "Virginia" },
                    { label: "Vermont", value: "Vermont" },
                    { label: "Washington", value: "Washington" },
                    { label: "Wisconsin", value: "Wisconsin" },
                    { label: "West Virginia", value: "West Virginia" },
                    { label: "Wyoming", value: "Wyoming" },
                  ]}
                  className="styled w-full m-[10px]"
                />
              </div>
              <div className="flex justify-center my-5">
                <UI.PieInput
                  type="text"
                  className="styled w-full"
                  placeholder="Business Type"
                  value={businessType}
                  onChange={handleBusinessTypeChange}
                  style={{ margin: "10px" }}
                />
              </div>
              <h1 className="text-center font-bold text-2lg text-black">
                ZIP Codes
              </h1>
              <div className="grid grid-cols-2 gap-4 m-4">
                {[...Array(zipInputAmount)].map((_, index) => (
                  <div className="relative w-full">
                    <UI.PieInput
                      className="w-full styled"
                      key={index}
                      type="text"
                      placeholder={`ZIP Code ${index + 1}`}
                      value={zipCodes[index]}
                      onChange={(event) => handleZipCodeChange(event, index)}
                    />
                    <span
                      className="absolute right-[8px] top-[50%] translate-y-[-50%]"
                      onClick={() => {
                        console.log(index);
                        setCurrentZIP(index);
                      }}
                    >
                      <UI.Icon
                        icon="geo-alt-fill"
                        customstyle="text-[#012047ff] text-xl cursor-pointer hover:text-[#01152e] duration-200"
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className="flex justify-center my-5">
                {zipInputAmount < 4 && (
                  <div
                    className="flex gap-2 w-[80%] lg:w-[50%] font-bold text-md py-2 rounded-md shadow-lg items-center justify-center cursor-pointer text-white bg-[#012047ff] hover:translate-y-[-5%] transition-all duration-200"
                    onClick={handleAddZipCodeInput}
                  >
                    <i className="bi bi-plus"></i>
                    <span>Add ZIP Code</span>
                  </div>
                )}
              </div>
              <div className="flex justify-center my-5">
                <div
                  onClick={handleSearch}
                  id="genButton"
                  className="flex gap-2 w-[80%] lg:w-[50%] font-bold text-md py-2 rounded-md shadow-lg items-center justify-center cursor-pointer text-white bg-[#012047ff] hover:translate-y-[-5%] transition-all duration-200"
                >
                  <i className="bi bi-search"></i>
                  <span>Search</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {mapLinks.length > 0 ? (
              <>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 m-5 p-5">
                  {mapLinks.map((map, index) => (
                    <LegDiv
                      key={index}
                      places={places}
                      map={map}
                      index={index}
                    ></LegDiv>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div
                  className={
                    "fixed bg-[#3d74cdff] text-white p-2 m-2 rounded-full flex items-center justify-center right-2 bottom-2 " +
                    "cursor-pointer hover:bg-[#264e8d] transition-all hover:translate-y-[-10%] shadow-md " +
                    "h-16 w-16 lg:h-24 lg:w-72"
                  }
                  onClick={handleGo}
                >
                  <div className="text-lg font-semibold p-4 hidden lg:block">
                    Let's Go ({places.length} Stops)
                  </div>
                  <div className="text-2xl">
                    <i className="bi bi-car-front-fill"></i>
                  </div>
                </div>
                <div className="my-2 flex flex-col w-full gap-2 p-2 lg:p-5">
                  <div className="text-[#3d74cdff] text-2xl font-semibold text-center lg:text-left lg:text-3xl">
                    {fixedPlacesLength} {businessType} Found
                  </div>
                  <div className="text-[#44986fff] text-center text-sm lg:text-left lg:text-base font-semibold">
                    You can only select a maximum of 20 {businessType}{" "}
                    locations.
                  </div>
                  <div className="text-[#012047ff] text-center text-sm lg:text-left lg:text-base">
                    Delete the ones you don't want to visit, then press Go.
                  </div>
                </div>
                <div
                  className={`grid grid-cols-1 gap-2 px-5 md:grid-cols-2 xl:grid-cols-3 xl:gap-4`}
                >
                  {places.map((place, index) => (
                    <div
                      key={index}
                      className="bg-[#ECF0F1] min-h-[196px] text-black shadow-md p-4 mb-4 rounded-md justify-evenly flex gap-2 items-center flex-col"
                    >
                      <div className="flex justify-between w-full items-center">
                        <span className="text-lg font-semibold text-[#012047ff]">
                          {place.name}
                        </span>
                      </div>
                      <div className="text-left w-full">{place.address}</div>
                      <div className="w-full flex items-end">
                        {places.length > 1 && (
                          <button
                            onClick={() => handleDelete(index)}
                            className="mt-2 bg-[#44986fff] cursor-pointer text-white font-bold py-2 px-4 rounded flex gap-2"
                          >
                            <i className="bi bi-trash"></i>
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
