import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import "../scss/Navbar.scss";
import { IconButton } from "./_IconButton";
import { Request, Handler, Error } from "../Connection/System.js";
import * as UI from "./_Misc.jsx";

const ErrorBox = () => <div className="_error_box"></div>;

export const Navbar = () => {
  /********************************************************************************/
  // User and Signed state
  const [signed, setSigned] = useState(false);
  const [user, setUser] = useState({});
  useState(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setSigned(true);
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);
  /********************************************************************************/
  // Navbar Items
  const NavItems = (props) => (
    <>
      <a href="/" className="link">
        Home
      </a>
      <a href="/route" className="link">
        Route
      </a>
      <a href="/post" className="link">
        Post Generator
      </a>
      <a href="/admin" className="link">
        Admin
      </a>
      {!props.mobile && (
        <div className="linkbreaker">
          <div className="temp"></div>
        </div>
      )}
      <p
        className="link creds_info"
        onClick={(event) => {
          if (signed) {
            hideSignInBox();
            showProfileBox(event);
          } else {
            hideProfileBox();
            showSignInBox(event);
          }
        }}
      >
        {signed ? user.username : "Sign In"}
      </p>
    </>
  );

  /********************************************************************************/
  // Mobile NavBar
  let popupOpen = false;
  const MobileNavBar = () => (
    <div id="navbar_popup">{<NavItems mobile={true} />}</div>
  );
  const showMobileNavbar = () => {
    // document.querySelector(".profile").style.display = "none";
    document.querySelector("#navbar_popup").style.display = "flex";
    // document.body.style.overflowY = "hidden";
  };
  const hideMobileNavbar = () => {
    // document.querySelector(".profile").style.display = "none";
    document.querySelector("#navbar_popup").style.display = "none";
    // document.body.style.overflowY = "auto";
  };

  /********************************************************************************/
  // Profile Box
  const showProfileBox = (event) => {
    // Stop the event propagation to prevent immediate hiding
    event.stopPropagation();
    document.querySelector("#profile_box").style.display = "block";
    document.body.addEventListener("click", outsideProfileClick);
  };
  const hideProfileBox = () => {
    document.querySelector("#profile_box").style.display = "none";
    // Remove the event listener when hiding the sign-in box
    document.body.removeEventListener("click", outsideProfileClick);
  };
  const outsideProfileClick = (event) => {
    const profileBox = document.querySelector("#profile_inner_box");
    // Check if the clicked element is outside the sign-in box
    if (!profileBox.contains(event.target)) {
      hideProfileBox();
    }
  };

  const ChangePassword = () => {
    return (
      <div
        className="absolute w-full h-full items-center justify-center"
        id="change_pass"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <div
          id="change_pass_inner"
          className="flex flex-col items-center justify-center bg-[#ECF0F1] py-12 px-4 rounded-md shadow-lg gap-8 min-w-[270px] z-30"
        >
          <div className="text-xl font-bold text-center text-[#012047ff]">
            Change Your Password
          </div>
          <UI.PieLabelInput label="Current Password" id="currpass" />
          <UI.PieLabelInput label="New Password" id="newpass" />
          <div className="flex gap-2 w-full">
            <UI.PieButton
              text="Change"
              icon="pencil"
              customstyle="w-full"
              onClick={async (e) => {
                e.stopPropagation();
                let res = await Handler.change_pass();
                if (res == false) {
                  return;
                }
                document.querySelector("#change_pass").style.display = "none";
                document.body.style.overflowY = "auto";
                window.location.reload();
              }}
            />
            <UI.PieButton
              text="Back"
              icon="box-arrow-right"
              customstyle="w-full"
              onClick={(e) => {
                e.stopPropagation();
                document.querySelector("#change_pass").style.display = "none";
                document.body.style.overflowY = "auto";
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const ProfileBox = () => {
    return (
      <div
        id="profile_box"
        className="absolute cursor-pointer w-full h-full z-30"
      >
        <div className={"absolute top-[16vh] right-0"} id="profile_inner_box">
          <div className="profile">
            <div className="section section-centered section-title">
              <span target="fullname">
                {user.firstName} {user.lastName}
              </span>
              <span target="username">@{user.username}</span>
            </div>
            <div className="section">
              <span>Email</span>
              <span target="email">{user.email}</span>
            </div>
            <div className="section section-btn-container section-centered">
              <UI.PieButton
                icon="box-arrow-left"
                text="Sign Out"
                customstyle="w-full lg:w-full"
                onClick={(event) => {
                  event.stopPropagation();
                  Handler.logout();
                  window.location.reload();
                }}
              />
            </div>
            <div className="section section-btn-container section-last section-centered">
              <UI.PieButton
                icon="pencil"
                text="Change Password"
                customstyle="w-full lg:w-full"
                onClick={(event) => {
                  event.stopPropagation();
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                  document.body.style.overflowY = "hidden";
                  document.querySelector("#change_pass").style.display = "flex";
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  /********************************************************************************/
  // SignIn Box
  const showSignInBox = (event) => {
    // Stop the event propagation to prevent immediate hiding
    event.stopPropagation();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.querySelector("#signin_box").style.display = "flex";
    document.body.addEventListener("click", outsideSignInClick);
    document.body.style.overflowY = "hidden";
  };
  const hideSignInBox = () => {
    document.querySelector("#signin_box").style.display = "none";
    // Remove the event listener when hiding the sign-in box
    document.body.removeEventListener("click", outsideSignInClick);
    document.body.style.overflowY = "auto";
  };
  const outsideSignInClick = (event) => {
    const signInBox = document.querySelector("#signin_inner_box");
    // Check if the clicked element is outside the sign-in box
    if (!signInBox.contains(event.target)) {
      hideSignInBox();
      // document.body.style.overflowY = "auto";
    }
  };

  const showSignInWarn = () => {
    document.querySelector("#signin_box").style.display = "flex";
    document.body.style.overflowY = "hidden";
    document.body.addEventListener("click", outsideSignInClick);
  };

  const SignInBox = () => {
    const [showSignup, setShowSignup] = useState(false);

    const Logup = () => (
      <>
        <div className="text-xl font-semibold">Signup to PiePay</div>
        <UI.PieLabelInput
          label="Username"
          id="username"
          customstyle="h-[20px]"
          customstyle_label="text-xs"
        />
        <UI.PieLabelInput
          label="First Name"
          id="firstname"
          customstyle="h-[20px]"
          customstyle_label="text-xs"
        />
        <UI.PieLabelInput
          label="Last Name"
          id="lastname"
          customstyle="h-[20px]"
          customstyle_label="text-xs"
        />
        <UI.PieLabelInput
          label="Password"
          id="password"
          type="password"
          customstyle="h-[20px]"
          customstyle_label="text-xs"
        />
        <UI.PieLabelInput
          label="Email"
          id="email"
          type="email"
          customstyle="h-[20px]"
          customstyle_label="text-xs"
        />
        <UI.PieButton
          icon="box-arrow-in-right"
          text="Signup"
          customstyle="w-[80%]"
          onClick={async (event) => {
            event.stopPropagation();
            if ((await Handler.signup()) == true) {
              window.location.reload();
            }
          }}
        />
        <div className="text-sm text-center">
          Already have an account?
          <br />
          <UI.PieAnchor
            onClick={async (event) => {
              event.stopPropagation();
              setShowSignup(false);
            }}
            label="Login here"
          />
          .
        </div>
      </>
    );
    const Login = () => (
      <>
        <div className="text-xl font-semibold">Login to PiePay</div>
        <UI.PieLabelInput label="Username" id="username" />
        <UI.PieLabelInput label="Password" id="password" type="password" />
        <UI.PieButton
          icon="box-arrow-in-right"
          text="Login"
          customstyle="w-[80%]"
          onClick={async (event) => {
            event.stopPropagation();

            if ((await Handler.login()) == true) {
              window.location.reload();
            }
          }}
        />
        <div className="text-sm text-center">
          Don't have an account?
          <br />
          <UI.PieAnchor
            onClick={(event) => {
              event.stopPropagation();
              setShowSignup(true);
            }}
            label="Register here"
          />
          .
        </div>
      </>
    );

    return (
      <div
        className="cursor-pointer absolute w-full h-full items-center justify-center"
        id="signin_box"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <div
          id="signin_inner_box"
          className="cursor-default flex flex-col items-center justify-center bg-[#ECF0F1] py-12 px-4 rounded-md shadow-lg gap-8 min-w-[270px] z-30"
        >
          {showSignup ? <Logup /> : <Login />}
        </div>
      </div>
    );
  };

  useState(() => {
    if (!sessionStorage.getItem("signed_warn_given")) {
      setTimeout(() => {
        try {
          showSignInWarn();
          sessionStorage.setItem("signed_warn_given", true);
        } catch (err) {
          console.error(err);
        }
      }, 500);
    }
  }, []);

  return (
    <>
      <ErrorBox />
      <MobileNavBar />
      <SignInBox />
      <ProfileBox />
      <ChangePassword />

      <div className="navbar">
        <div className="imgcontainer">
          <img
            src="https://cdn.discordapp.com/attachments/964941567053922414/1173667956278972446/WhatsApp_Image_2023-10-26_at_01.53.13_5d526daf-removebg-preview.png?ex=6564ca76&is=65525576&hm=736b77bdf37aa872faec8681896e5353c08e8ba7eb4a3373e9341d1c37eeaa21&"
            alt="PiePay"
          />
        </div>
        <div className="linkscontainer">
          <div className="after-lg linksgroup">{<NavItems />}</div>
          <div className="below-lg">
            <IconButton
              icon="list"
              onClick={async () => {
                popupOpen ? hideMobileNavbar() : showMobileNavbar();
                popupOpen = !popupOpen;
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
