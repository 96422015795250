import React, { useState, useEffect } from "react";
import { Navbar } from "../components/Navbar";
import * as UI from "../components/_Misc";
import { System, Error, Handler } from "../Connection/System.js";

import "../scss/Admin.scss";
import "../scss/AdminPanel.scss"; // Import the Sass file for styling

export function Admin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginSuccessful, setLoginSuccessful] = useState(false);

  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  function AdminPanel() {
    // Handle checkbox change for selecting all users
    const handleSelectAll = () => {
      setSelectAll(!selectAll);
      setSelectedUsers(selectAll ? [] : filteredUsers.map((user) => user._id));
      setTimeout(() => {
        console.log(selectedUsers);
      }, 500);
    };

    // Handle checkbox change for selecting a single user
    const handleSelectUser = (userId) => {
      setSelectedUsers((prevSelected) =>
        prevSelected.includes(userId)
          ? prevSelected.filter((id) => id !== userId)
          : [...prevSelected, userId]
      );
    };

    // Handle delete button click for a single user
    const handleDeleteUser = async (userId) => {
      try {
        const res = await Handler.delete_user(userId);
        if (res == false) {
          return false;
        }
        // Update the state to remove the deleted user
        setUsers(res);
        setUsersCount(res.length);
        // setUsers((prevUsers) =>
        //   prevUsers.filter((user) => user._id !== userId)
        // );
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    };

    // Handle delete selected button click
    const handleDeleteSelected = async () => {
      try {
        // Assuming you have a DELETE endpoint to delete multiple users

        const res = await Handler.delete_selected_users(selectedUsers);
        if (res == false) return;
        // Update the state to remove the deleted users
        setUsers(res);
        setUsersCount(res.length);
        setSelectedUsers([]); // Clear the selected users
      } catch (error) {
        console.error("Error deleting selected users:", error);
      }
    };

    // Filter users based on search term
    const filteredUsers = users.filter((user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <div className="admin-panel">
        <div className="flex justify-center items-center text-center">
          <div className="bg-[#44986fff] text-white font-semibold m-4 px-6 py-4 rounded-md shadow-xl">
            Registered Users: {usersCount}
          </div>
        </div>
        <div className="action-bar my-5">
          <div className="flex items-center">
            <UI.PieInput
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <label>Select All</label>
          </div>
          <input
            type="text"
            placeholder="Search by username"
            value={searchTerm}
            id="search_term"
            className="outline-none border-2 px-2 rounded-sm"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setTimeout(() => {
                document.querySelector("#search_term").focus();
              }, 100);
            }}
          />
          <UI.PieButton
            text="Delete Selected"
            customstyle="w-[196px]"
            icon="trash"
            onClick={handleDeleteSelected}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>Select</th>
              <th>ID</th>
              <th>Username</th>
              <th>Email</th>
              <th>Password</th>
              <th>Full Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user._id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user._id)}
                    onChange={() => handleSelectUser(user._id)}
                  />
                </td>
                <td>{user._id}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.password}</td>
                <td>
                  {user.firstName} {user.lastName}
                </td>
                <td>
                  <div
                    className="w-full flex items-center justify-center text-red-600 cursor-pointer text-xl"
                    onClick={() => handleDeleteUser(user._id)}
                  >
                    <UI.Icon icon="trash-fill" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  const handleLogin = async () => {
    let d = await Handler.admin_verify(username, password);
    if (d == false) {
      return;
    }
    console.log(d);
    setLoginSuccessful(true);
    setUsers(d);
    setUsersCount(d.length);
  };

  useEffect(() => {
    document.body.style.overflowY = "auto";
  });

  return (
    <>
      <Navbar />
      {isLoginSuccessful ? (
        <AdminPanel />
      ) : (
        <div className="login-container">
          <div className="flex flex-col gap-0">
            <UI.PieLabel text="Admin Username" customstyle="text-[#3d74cdff]" />
            <UI.PieInput
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className="opacity-0">.</div>
            <div className="opacity-0">.</div>
            <UI.PieLabel text="Key" />
            <UI.PieInput
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="opacity-0">.</div>
            <div className="opacity-0">.</div>
            <UI.PieButton
              onClick={handleLogin}
              text="Login"
              icon="box-arrow-right"
              customstyle="w-full"
            />
          </div>
        </div>
      )}
    </>
  );
}
