import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
export function NoPage() {
  useEffect(() => {
    document.body.style.overflowY = "auto";
  });
  return <h1>404.</h1>;
}
