const GOOGLE_MAPS_API_KEY = "AIzaSyDMdmStDfeNhvoHD5BucY4vUnVbqVzD_DM";
export const getPlaces = async (zipCode, businessType) => {
  const response = await fetch(
    `https://piepay-server.onrender.com/maps-api/maps/api/place/textsearch/json?query=${businessType}+in+${zipCode}&key=${GOOGLE_MAPS_API_KEY}`
  );

  const data = await response.json();

  if (data.status === "OK") {
    return data.results.map((result) => ({
      place_id: result.place_id,
      name: result.name,
      address: result.formatted_address,
      coordinates: result.geometry.location,
    }));
  } else {
    throw new Error(data.status);
  }
};

// Function to encode special characters in a string for URL
function encodeURIComponentRFC3986(str) {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16)}`
  );
}

// Function to remove everything before the first comma in the address
function cleanAddress(address) {
  // console.log(address);
  if (address === undefined) return address;
  let i = address.split(",");
  if (i.length == 1) return address;
  return i[1];
}

const generateLeg = (legPoints, firstLeg = false) => {
  // Build the Google Maps link
  let mapsLink = `https://www.google.com/maps/dir/?api=1&mode=driving`;

  legPoints.forEach((waypoint, index) => {
    const { name, address, coordinates } = waypoint;
    const cleanedAddress = cleanAddress(address);
    const waypointString = `${encodeURIComponentRFC3986(
      name
    )},${encodeURIComponentRFC3986(cleanedAddress)}`;

    if (index === 0 && firstLeg == false) {
      mapsLink += `&origin=${waypointString}`;
    } else if (index === legPoints.length - 1) {
      mapsLink += `&destination=${waypointString}`;
    } else if (index === 0 && firstLeg) {
      mapsLink += `&waypoints=${waypointString}`;
    } else if (index === 1 && firstLeg === false) {
      mapsLink += `&waypoints=${waypointString}`;
      // } else if ((index === 1 && firstLeg) || index === 0) {
      //   console.log("Condition True");
      //   mapsLink += `&waypoints=${waypointString}`;
    } else {
      mapsLink += `|${waypointString}`;
    }
  });

  return mapsLink;
};

export const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    if (i > 0) {
      const lastItemOfPreviousChunk =
        chunks[chunks.length - 1][chunks[chunks.length - 1].length - 1];
      chunk.unshift(lastItemOfPreviousChunk);
    }
    chunks.push(chunk);
  }
  console.log({ chunks });
  return chunks;
};

export const generateMapsLink = (optimizedWaypoints) => {
  const legChunks = chunkArray(optimizedWaypoints, 10);

  const mapsLinks = legChunks.map((legPoints, index) => {
    const firstLeg = index === 0;
    return generateLeg(legPoints, firstLeg);
  });

  return mapsLinks;
};

export function convertToMapEmbed(placeArray, chunk) {
  let markers = "";

  const legChunks = chunkArray(placeArray, 10);
  let places = legChunks[chunk];

  places.forEach((place, index) => {
    const { coordinates } = place;
    const { lat, lng } = coordinates;

    // Convert the index to a character (A for 1, B for 2, etc.)
    const label = String.fromCharCode(65 + index);
    markers += `&markers=color:purple|label:${label}|${lat},${lng}`;
  });

  let link = `https://maps.googleapis.com/maps/api/staticmap?size=480x480&maptype=roadmap${markers}&key=${GOOGLE_MAPS_API_KEY}`;
  return link;
}

/*
https://maps.googleapis.com/maps/api/staticmap?
size=600x450&maptype=roadmap&
markers=color:red|Chachu%2520Refreshment%2C%2520Officers%2520Colony%2520Wah%2520Cantt|Classic%2520Naan%2520Center%2C%2520Street%252029|Anwaria%2520Restaurant%2C%2520Peshawar-Rawalpindi%2520Rd|Naan%2520Point%2C%2520Shop%2520%2523%2520LG-5A%2520VinTower%25201%2520Kohistan%2520Enclave%2520F|Pizza%2520Cafe%2C%2520Wah%2520Cantt|Sadaat%2520Foods%2520Branch%25202%2C%2520Wah%2520Cantt|The%2520Afghanian%2520Foods%2C%2520Wah|Butt%2520Karahi%2520Wah%2C%2520Wah|KDs%2520Restaurant%2C%2520near%2520Cantt&markers=color:blue|Mughalia%2520Baithak%2C%2520near%2520Wah&path=color:0x0000ff|weight:5|Chachu%2520Refreshment%2C%2520Officers%2520Colony%2520Wah%2520Cantt|Classic%2520Naan%2520Center%2C%2520Street%252029|Anwaria%2520Restaurant%2C%2520Peshawar-Rawalpindi%2520Rd|Naan%2520Point%2C%2520Shop%2520%2523%2520LG-5A%2520VinTower%25201%2520Kohistan%2520Enclave%2520F|Pizza%2520Cafe%2C%2520Wah%2520Cantt|Sadaat%2520Foods%2520Branch%25202%2C%2520Wah%2520Cantt|The%2520Afghanian%2520Foods%2C%2520Wah|Butt%2520Karahi%2520Wah%2C%2520Wah|KDs%2520Restaurant%2C%2520near%2520Cantt|Mughalia%2520Baithak%2C%2520near%2520Wah&key=AIzaSyDMdmStDfeNhvoHD5BucY4vUnVbqVzD_DM
*/

// export function convertToMapEmbed(googleMapsUrl) {
//   try {
//     // Extract waypoints and destination from the URL
//     const waypointsRegex = /waypoints=([^&]+)/;
//     const destinationRegex = /destination=([^&]+)/;
//     const waypointsMatch = googleMapsUrl.match(waypointsRegex);
//     const destinationMatch = googleMapsUrl.match(destinationRegex);

//     if (!waypointsMatch || !destinationMatch) {
//       // Handle invalid URL
//       throw new Error("Invalid Google Maps URL");
//     }

//     const waypoints = waypointsMatch[1].split("|");
//     const destination = destinationMatch[1];

//     // Replace spaces with '+' in the addresses
//     const formattedWaypoints = waypoints.map((waypoint) =>
//       encodeURIComponent(waypoint.replace(/ /g, "+"))
//     );

//     // Construct the Google Static Maps API URL
//     const apiKey = GOOGLE_MAPS_API_KEY;
//     const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?size=600x450&maptype=roadmap&markers=color:red|${formattedWaypoints.join(
//       "|"
//     )}&markers=color:blue|${encodeURIComponent(
//       destination.replace(/ /g, "+")
//     )}&path=color:0x0000ff|weight:5|${formattedWaypoints.join(
//       "|"
//     )}|${encodeURIComponent(destination.replace(/ /g, "+"))}&key=${apiKey}`;
//     console.log(mapUrl);
//     return mapUrl;
//   } catch (error) {
//     console.error("Error generating static map URL:", error.message);
//     return null;
//   }
// }

/*


https://www.google.com/maps/dir/?api=1&mode=driving&waypoints=
Malang Jaan Beef Pulao, Wah Cantt
&waypoints=Malang jaan beef pulao, stop h 23
Malang Jan Bannu Beef Pulao, Grand Trunk Rd
Malang Jan Bannu Beef Pulao, Mohammadabad Westridge%D8%8C Rawalpindi
&destination=Malang Jan Bannu Beef Pulao, Chand Plaza



https://www.google.com/maps/dir/?api=1&origin=Sadaat%20Foods%20Branch%202,%20Wah%20Cantt&waypoints=MR%20DAAL%20CHAAWAL,%20Wah%20Cantt|Ubaid%20minhas,%20Loshar%20sharfoo|Milano%20Pizza,%20Cantt|The%20Raj%27s%20Tarka,%20Qabristan%20Road|Anwaria%20Restaurant,%20Peshawar-Rawalpindi%20Rd|Chachu%20Refreshment,%20Officers%20Colony%20Wah%20Cantt|Classic%20Naan%20Center,%20Street%2029|Doodh%20Patti%20Hotel,%20Street%2029&destination=Pizza%20Cafe,%20Wah%20Cantt

https://www.google.com/maps/dir/?api=1&waypoints=Pizza%20Cafe,%20Wah%20Cantt|Naan%20Point,%20Shop%20%23%20LG-5A%20VinTower%201%20Kohistan%20Enclave%20F|Saifullah%20khyber%20shinwari,%20Wah|Crisp%20craze,%20Phase%202%20New%20City|Okkie%20Dokie,%20Shop%20%23%2005|Tandoori%20Restaurant%20New%20City%20Wah,%20BLOCK%20A|Domino%20pizza,%20Main%20G%20t%20Road|YASEEN%20TIKKA,%20Wah|Sajjad%20Ahmed%20chicken%20shop,%20kokara|Butt%20Karahi%20Wah,%20Wah&destination=New%20Gujranwala%20hottel%20%26%20Restaurant,%20Abudawood%20Trading%20Co.%20Leo%27s%20Plaza%20Wah%20Garden
https://www.google.com/maps/dir/?api=1&mode=driving&optimizeWaypoints=true&waypoints=Pizza%20Cafe,%20Wah%20Cantt|Naan%20Point,%20Shop%20%23%20LG-5A%20VinTower%201%20Kohistan%20Enclave%20F|Saifullah%20khyber%20shinwari,%20Wah|Crisp%20craze,%20Phase%202%20New%20City|Okkie%20Dokie,%20Shop%20%23%2005|Tandoori%20Restaurant%20New%20City%20Wah,%20BLOCK%20A|Domino%20pizza,%20Main%20G%20t%20Road|YASEEN%20TIKKA,%20Wah|Sajjad%20Ahmed%20chicken%20shop,%20kokara|Butt%20Karahi%20Wah,%20Wah&destination=New%20Gujranwala%20hottel%20%26%20Restaurant,%20Abudawood%20Trading%20Co.%20Leo%27s%20Plaza%20Wah%20Garden
*/

// export const generateMapsLink = (optimizedWaypoints) => {
//   // Build the Google Maps link
//   let mapsLink = `https://www.google.com/maps/dir/?api=1`;

//   optimizedWaypoints.forEach((waypoint, index) => {
//     const { name, address, coordinates } = waypoint;
//     const cleanedAddress = cleanAddress(address);
//     const waypointString = `${encodeURIComponentRFC3986(
//       name
//     )},${encodeURIComponentRFC3986(cleanedAddress)}`;
//     console.log(name);

//     if (index === optimizedWaypoints.length - 1) {
//       mapsLink += `&destination=${waypointString}`;
//     } else if (index === 0) {
//       mapsLink += `&waypoints=${waypointString}`;
//     } else {
//       mapsLink += `|${waypointString}`;
//     }
//   });

//   // Open the link in a new tab or redirect the user to it
//   console.log({ mapsLink });
//   window.open(mapsLink, "_blank");
// };
