import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

/**
 * Render a hero section with a background image, title, and description.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the hero section.
 * @param {string} props.description - The description of the hero section.
 * @param {string} props.image - The URL of the background image.
 * @returns {JSX.Element} - The rendered hero section.
 */
export function HeroSection({ title, description, image }) {
  // Set the background image style
  const backgroundImageStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  // Set container miage style
  const containerStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };

  // Render the hero section
  return (
    <div className={`h-[50vh]`} style={backgroundImageStyle}>
      <div
        className={
          "p-2 h-[50vh] text-white items-center justify-center flex flex-col text-center "
        }
        style={containerStyle}
      >
        <h1 className={"text-3xl font-bold " + "sm:text-4xl "}>{title}</h1>
        <p className={"mt-2 text-sm " + "sm:text-md"}>{description}</p>
      </div>
    </div>
  );
}

export function PieSelect(props) {
  return (
    <select
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      className={
        "border-b-2 border-[#012047ff] text-[#012047ff] outline-none bg-transparent font-semibold px-2 m-0 h-[36px] " +
        props.className +
        ` ` +
        props.customstyle
      }
    >
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export function PieLabel(props) {
  return (
    <label
      className={`text-[#44986fff] text-sm font-semibold ` + props.className}
    >
      {props.text}
    </label>
  );
}

export function PieInput(props) {
  return (
    <input
      {...props}
      className={
        "border-b-2 border-[#012047ff] text-[#012047ff] outline-none bg-transparent font-semibold px-2 m-0 h-[36px] " +
        props.className
      }
    />
  );
}
// Combination of above two
export function PieLabelInput(props) {
  return (
    <div className="flex flex-col gap-0">
      <PieLabel text={props.label} className={props.customstyle_label} />
      <PieInput
        {...props}
        className={`px-0 h-[28px] text-sm ` + props.customstyle}
      />
    </div>
  );
}

// anchor tag
export function PieAnchor(props) {
  return (
    <a
      {...props}
      className={
        `text-[#3d74cdff] underline cursor-pointer ` + props.customstyle
      }
    >
      {props.label}
    </a>
  );
}

export function PieButton(props) {
  return (
    <button
      className={
        `flex gap-2 py-3 w-[100%] lg:w-[50%] font-bold text-md lg:py-2 rounded-md shadow-lg items-center justify-center cursor-pointer text-white bg-[#012047ff] hover:translate-y-[-5%] transition-all duration-200 ` +
        props.customstyle
      }
      {...props}
    >
      <i className={`bi bi-${props.icon}`}></i>
      <span>{props.text}</span>
    </button>
  );
}

export function Icon(props) {
  return <i className={`bi bi-${props.icon} ${props.customstyle}`}></i>;
}
