import axios from "axios";
const SERVER = "https://piepay-server.onrender.com";

/****************************************************************************************************/
/****************************************************************************************************/
/****************************************************************************************************/
const Request = {};
Request.get = async (path) => {
  try {
    let data = await axios.get(`${SERVER}${path}`);
    return data.data;
  } catch (err) {
    console.error(`HTTP error: ${err.response.status}`);
    return {
      error: true,
      message: err.response.message,
      status: err.response.status,
    };
  }
};
Request.post = async (path, data) => {
  try {
    let res = await axios.post(`${SERVER}${path}`, data);
    return res.data;
  } catch (err) {
    console.error(`HTTP error: ${err.response.status}`);
    return {
      error: true,
      message: err.response.statusText,
      status: err.response.status,
    };
  }
};
/****************************************************************************************************/
/****************************************************************************************************/
/****************************************************************************************************/
const Error = {};
Error.error = (m) => {
  let i = document.querySelector("._error_box");

  i.innerHTML = m;
  i.style.display = "flex";

  setTimeout(() => {
    i.style.display = "none";
  }, 2000);
};

/****************************************************************************************************/
/****************************************************************************************************/
/****************************************************************************************************/
const Route = {};
Route.getPlaces = async (country, zipcodes, type) => {
  console.log({ country, zipcodes, type });

  let data = await Request.post("/route/getPlaces", {
    country: country,
    zips: zipcodes,
    type: type,
    token: localStorage.getItem("token"),
  });
  if (data.error) {
    Error.error("Invalid ZIP Code.");
    return false;
  }
  return data.places;
};

Route.links = async (places) => {
  let data = await Request.post("/route/generateLinks", {
    token: localStorage.getItem("token"),
    places: places,
  });
  if (data.error) {
    Error.error("Can't generate Route.");
    return false;
  }
  return data.links;
};

Route.getZipCodes = async (city, country) => {
  let data = await Request.post("/route/getZipCodes", {
    token: localStorage.getItem("token"),
    city: city,
    country: country,
  });
  if (data.error) {
    Error.error(data.message);
    return false;
  }
  return data.zips;
};

/****************************************************************************************************/
/****************************************************************************************************/
/****************************************************************************************************/
const Handler = {};

Handler.login = async () => {
  let username = document.querySelector("#username").value;
  let password = document.querySelector("#password").value;

  const usernameRegex = /^[a-zA-Z0-9_]+$/;
  if (!usernameRegex.test(username) || username.length < 1) {
    Error.error("Username only contains letters, numbers, or underscores.");
    return false;
  }

  if (password.length < 6) {
    Error.error("Password is always at least 6 characters long.");
    return false;
  }

  let data = await Request.post("/users/login", {
    username: username,
    password: password,
  });

  if (data.error) {
    Error.error("Invalid credentials.");
    return false;
  }

  localStorage.setItem("token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
  return true;
};

Handler.signup = async () => {
  let username = document.querySelector("#username").value;
  let password = document.querySelector("#password").value;
  let email = document.querySelector("#email").value;
  let firstname = document.querySelector("#firstname").value;
  let lastname = document.querySelector("#lastname").value;

  const usernameRegex = /^[a-zA-Z0-9_]+$/;
  if (!usernameRegex.test(username) || username.length < 1) {
    Error.error("Username must only contain letters, numbers, or underscores.");
    return false;
  }
  if (password.length < 6) {
    Error.error("Password must be at least 6 characters long.");
    return false;
  }
  const emailRegex = /^\S+@\S+\.\S+$/;
  if (!emailRegex.test(email)) {
    Error.error("Invalid email address.");
    return false;
  }
  if (firstname.length < 1) {
    Error.error("First name is required.");
    return false;
  }

  if (lastname.length < 1) {
    Error.error("Last name is required.");
    return false;
  }

  let data = await Request.post("/users/register", {
    username: username,
    password: password,
    email: email,
    firstName: firstname,
    lastName: lastname,
  });

  if (data.error) {
    Error.error(`${data.message}`);
    return false;
  }

  localStorage.setItem("token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
  return true;
};

Handler.logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

Handler.change_pass = async () => {
  let currpass = document.querySelector("#currpass").value;
  let newpass = document.querySelector("#newpass").value;

  if (currpass.length < 1 || newpass.length < 1) {
    Error.error("Both fields are required.");
    return false;
  }

  let data = await Request.post("/users/changePass", {
    token: localStorage.getItem("token"),
    currpass: currpass,
    newpass: newpass,
  });
  if (data.error) {
    Error.error(`Wrong password.`);
    return false;
  }
  localStorage.setItem("user", JSON.stringify(data.user));
  return true;
};

Handler.admin_verify = async (username, key) => {
  let data = await Request.post("/admin/verify", {
    username,
    key,
  });
  if (data.error) {
    Error.error(data.message);
    return false;
  }
  sessionStorage.setItem("admin_username", username);
  sessionStorage.setItem("admin_key", key);
  return data.users;
};

Handler.delete_user = async (userId) => {
  let data = await Request.post("/admin/delete", {
    username: sessionStorage.getItem("admin_username"),
    key: sessionStorage.getItem("admin_key"),
    _id: userId,
  });

  if (data.error) {
    Error.error(`${data.message}`);
    return false;
  }
  return data.users;
};

Handler.delete_selected_users = async (userIds) => {
  let data = await Request.post("/admin/deleteSelected", {
    username: sessionStorage.getItem("admin_username"),
    key: sessionStorage.getItem("admin_key"),
    _ids: userIds,
  });
  if (data.error) {
    Error.error(`${data.message}`);
    return false;
  }
  return data.users;
};

Handler.verify = async () => {
  console.log("Waoo");
  if (localStorage.getItem("token")) {
    let data = await Request.post("/users/verify", {
      token: localStorage.getItem("token"),
    });
    if (data.error) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

/****************************************************************************************************/
/****************************************************************************************************/
/****************************************************************************************************/
export { Request, Error, Handler, Route };
