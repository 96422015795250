import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Navbar } from "../components/Navbar";
import * as UI from "../components/_Misc";
import hero_bg from "../assets/ai_post.jpg";
import { generateSocialMediaPost } from "../api/openai";
import { redirect } from "react-router-dom";
import { Handler } from "../Connection/System";

function Section_Hero() {
  return (
    <div className="flex w-full items-center justify-center flex-col gap-1 py-8 text-center px-1">
      <div className="text-2xl sm:text-[2.5rem] font-bold text-[#44986fff]">
        AI Post Generator
      </div>
      <div className="text-md sm:text-lg text-black">
        Instantly craft captivating social media posts.
      </div>
    </div>
  );
}

function Section_Tool() {
  const [data, setData] = useState({});

  // State to manage form input values
  const [wordCount, setWordCount] = useState("");
  const [platform, setPlatform] = useState("Facebook");
  const [topic, setTopic] = useState("");
  const [includeImage, setIncludeImage] = useState(true);

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Form validating
    if (!wordCount || !platform || !topic) {
      alert("Please fill in all the fields.");
      return;
    }
    // You can perform additional validation or processing here
    processPost();
  };

  // Function to handle the post processing
  const processPost = async () => {
    // Implement your logic for post processing here
    console.log("Processing post...");
    console.log("Word Count:", wordCount);
    console.log("Platform:", platform);
    console.log("Topic:", topic);
    console.log("Inlcude image:", includeImage);

    document.querySelector("#genButton").outerHTML =
      '<div class="spinner"></div>';

    let res = await generateSocialMediaPost(
      wordCount,
      platform,
      topic,
      includeImage
    );
    if (res == false) {
      return;
    }
    console.log(res);
    setData(res);
  };

  return (
    <div className="">
      {
        // if data has anything then render a div with the data
        (data.post && (
          <div className="mx-2 md:mx-auto my-5 flex flex-col justify-center items-center bg-[#ecf0f1] px-3 py-12 gap-2 rounded-2xl shadow-md md:max-w-[720px]">
            <div className="text-2xl font-bold text-[#44986fff]">
              Generated Post
            </div>
            <div className="text-[#012047ff] font-semibold">{data.post}</div>
            {data.image && (
              <img
                src={data.image}
                alt="AI Image"
                className="rounded-md shadow-md"
              />
            )}
            <div className="grid w-full gap-2 grid-cols-2">
              {data.image && (
                <UI.PieButton
                  text="Download Image"
                  icon="download"
                  onClick={() => window.open(data.image)}
                />
              )}
              <UI.PieButton
                text="Copy Text"
                icon="clipboard"
                onClick={() => navigator.clipboard.writeText(data.post)}
              />
              <UI.PieButton
                text="Generate Another"
                icon="plus"
                onClick={() => {
                  setData({});
                }}
              />
            </div>
          </div>
        )) || (
          // else render a form
          <form
            onSubmit={handleSubmit}
            className="mx-2 md:mx-auto my-5 flex flex-col justify-center items-center bg-[#ecf0f1] px-3 py-12 gap-5 rounded-2xl shadow-md md:max-w-[480px]"
          >
            <div className="flex flex-col w-full px-2">
              <label className="text-[#44986fff]">Word Length</label>
              <UI.PieInput
                type="number"
                value={wordCount}
                onChange={(e) => setWordCount(e.target.value)}
                className="w-[100%]"
                required={true}
              />
            </div>

            <div className="flex flex-col w-full px-2">
              <label className="text-[#44986fff]">Platform</label>
              <UI.PieSelect
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
                required={true}
                className="w-[100%]"
                options={[
                  { value: "Facebook", label: "Facebook" },
                  { value: "Twitter", label: "Twitter" },
                  { value: "Instagram", label: "Instagram" },
                  { value: "LinkedIn", label: "LinkedIn" },
                ]}
              />
            </div>
            <div className="flex flex-col w-full px-2">
              <label className="text-[#44986fff]">Topic</label>
              <UI.PieInput
                type="text"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                required={true}
                className="w-[100%]"
              />
            </div>

            <div className="flex flex-col w-full px-2">
              <label className="text-[#44986fff]">Include Image</label>
              <UI.PieSelect
                value={includeImage}
                onChange={(e) => setIncludeImage(e.target.value)}
                required={true}
                className="w-[100%]"
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />
            </div>

            <UI.PieButton
              icon="plus-lg"
              text="Generate Post"
              customstyle="w-[90%]"
              id="genButton"
              onClick={handleSubmit}
            />
          </form>
        )
      }
    </div>
  );
}

export function PostGenerator() {
  const backgroundImageStyle = {
    backgroundImage: `url(${hero_bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  useEffect(() => {
    async function check() {
      let d = await Handler.verify();
      if (d == false) {
        alert("Create an account to use this tool.");
        window.location.href = "/";
      }
    }
    check();
  }, []);

  useEffect(() => {
    document.body.style.overflowY = "auto";
  });

  // Set container miage style
  const containerStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };
  return (
    <div className="min-h-[100vh]">
      <div className="min-h-[100vh]">
        <Navbar />
        <Section_Hero />
        <Section_Tool />
        <div className="h-[2rem]"></div>
      </div>
    </div>
  );
}
