// Import axios
import axios from "axios";

let SERVER_URL = "https://piepay-server.onrender.com";
const ServerRequest = {};
ServerRequest.get = async (path) => {
  try {
    let data = await axios.get(`${SERVER_URL}${path}`);
    return data.data;
  } catch (err) {
    console.error(`HTTP error: ${err.response.status}`);
    return {
      error: true,
      message: err.response.message,
      status: err.response.status,
    };
  }
};
ServerRequest.post = async (path, data) => {
  try {
    let res = await axios.post(`${SERVER_URL}${path}`, data);
    return res.data;
  } catch (err) {
    console.error(`HTTP error: ${err.response.status}`);
    return {
      error: true,
      message: err.response.statusText,
      status: err.response.status,
    };
  }
};

function showError(m) {
  let i = document.querySelector("._error_box");

  i.innerHTML = m;
  i.style.display = "flex";

  setTimeout(() => {
    i.style.display = "none";
  }, 2000);
}

export async function generateSocialMediaPost(
  length,
  platform,
  topic,
  includeImage
) {
  let response = await ServerRequest.post("/post/new", {
    length: length,
    platform: platform,
    topic: topic,
    includeImage: includeImage,
    token: localStorage.getItem("token"),
  });
  if (response.error) {
    showError(response.message);
    return false;
  }
  console.log(response);
  return response;
}
