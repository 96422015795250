import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./scss/root.scss";

import { Home } from "./pages/Home";
import { NoPage } from "./pages/404";
import { Admin } from "./pages/Admin";
import { RouteFinder } from "./pages/RouteFinder";
import { PostGenerator } from "./pages/PostGenerator";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        {/* <Route path="test_url" element={<TestPage />} /> */}
        <Route path="route" element={<RouteFinder />} />
        <Route path="post" element={<PostGenerator />} />
        <Route path="admin" element={<Admin />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
