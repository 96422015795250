const axios = require("axios");
const GOOGLE_MAPS_API_KEY = "AIzaSyDMdmStDfeNhvoHD5BucY4vUnVbqVzD_DM";

const calculateDistance = (point1, point2) => {
  const lat1 = point1.coordinates.lat;
  const lng1 = point1.coordinates.lng;
  const lat2 = point2.coordinates.lat;
  const lng2 = point2.coordinates.lng;
  // Haversine formula for distance calculation
  const R = 6371; // Earth radius in kilometers
  const dlat = (lat2 - lat1) * (Math.PI / 180);
  const dlon = (lng2 - lng1) * (Math.PI / 180);
  const a =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dlon / 2) *
      Math.sin(dlon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};
export const nearestNeighbor = (places) => {
  const n = places.length;
  const visited = Array(n).fill(false);
  // Start with the first place as the initial waypoint
  const initialIndex = 0;
  let currentIndex = initialIndex;
  visited[currentIndex] = true;
  const optimizedOrder = [{ ...places[currentIndex], rank: 1 }];
  for (let i = 2; i <= n; i++) {
    let minDistance = Infinity;
    let nextIndex = -1;
    // Find the nearest unvisited place
    for (let j = 0; j < n; j++) {
      if (!visited[j]) {
        const distance = calculateDistance(places[currentIndex], places[j]);
        if (distance < minDistance) {
          minDistance = distance;
          nextIndex = j;
        }
      }
    }
    // Move to the next nearest place
    if (nextIndex !== -1) {
      currentIndex = nextIndex;
      visited[currentIndex] = true;
      optimizedOrder.push({ ...places[currentIndex], rank: i });
    } else {
      // All places have been visited, exit early
      break;
    }
  }
  return optimizedOrder;
};

// const calculateDistance = (point1, point2) => {
//   const lat1 = point1.coordinates.lat;
//   const lng1 = point1.coordinates.lng;
//   const lat2 = point2.coordinates.lat;
//   const lng2 = point2.coordinates.lng;
//   // Haversine formula for distance calculation
//   const R = 6371; // Earth radius in kilometers
//   const dlat = (lat2 - lat1) * (Math.PI / 180);
//   const dlon = (lng2 - lng1) * (Math.PI / 180);
//   const a =
//     Math.sin(dlat / 2) * Math.sin(dlat / 2) +
//     Math.cos(lat1 * (Math.PI / 180)) *
//       Math.cos(lat2 * (Math.PI / 180)) *
//       Math.sin(dlon / 2) *
//       Math.sin(dlon / 2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   const distance = R * c;
//   return distance;
// };
// export const nearestNeighbor = (places) => {
//   const n = places.length;
//   const visited = Array(n).fill(false);
//   // Start with the first place as the initial waypoint
//   const initialIndex = 0;
//   let currentIndex = initialIndex;
//   visited[currentIndex] = true;
//   const optimizedOrder = [places[currentIndex]];
//   for (let i = 1; i < n; i++) {
//     let minDistance = Infinity;
//     let nextIndex = -1;
//     // Find the nearest unvisited place
//     for (let j = 0; j < n; j++) {
//       if (!visited[j]) {
//         const distance = calculateDistance(places[currentIndex], places[j]);
//         if (distance < minDistance) {
//           minDistance = distance;
//           nextIndex = j;
//         }
//       }
//     }
//     // Move to the next nearest place
//     if (nextIndex !== -1) {
//       currentIndex = nextIndex;
//       visited[currentIndex] = true;
//       optimizedOrder.push(places[currentIndex]);
//     } else {
//       // All places have been visited, exit early
//       break;
//     }
//   }
//   return optimizedOrder;
// };

export async function optimize(waypoints) {
  // Create an array of LatLng strings for the waypoints
  const waypointsLatLng = waypoints
    .map((point) => `${point.coordinates.lat},${point.coordinates.lng}`)
    .join("|");

  try {
    let path = `https://piepay-server.onrender.com/maps-api/maps/api/directions/json?origin=${waypointsLatLng}&destination=${waypointsLatLng}&waypoints=optimize:true|${waypointsLatLng}&key=${GOOGLE_MAPS_API_KEY}`;
    console.log(path);
    // Make a request to Google Maps Directions API for route optimization
    const response = await fetch(path);

    if (!response.ok) {
      throw new Error(`Failed to fetch directions. Status: ${response.status}`);
    }

    const responseData = await response.json();

    // Extract the optimized order of waypoints from the response
    console.log(responseData);
    const optimizedOrder = responseData.routes[0].waypoint_order;

    // Rearrange the waypoints array based on the optimized order
    const optimizedWaypoints = optimizedOrder.map((index) => waypoints[index]);

    return optimizedWaypoints;
  } catch (error) {
    console.error("Error optimizing route:", error.message);
    throw error;
  }
}
