import { Navbar } from "../components/Navbar";
import { Link } from "react-router-dom";
import img_route from "../assets/intelligent_route_planning.png";
import img_post from "../assets/ai_post_Gen.png";
import { useEffect } from "react";

const Card = (props) => (
  <div
    className={`flex w-full items-center justify-evenly ${props.mobile} lg:flex-row`}
  >
    {props.children}
  </div>
);
const CardImage = (props) => (
  <img
    src={props.src}
    alt="Card Image"
    className="h-[50vh] object-cover p-2 rounded-lg shadow-md lg:w-[30vw] lg:h-auto"
  />
);
const CardParagraph = (props) => (
  <div className="w-[100vw] p-2 text-center lg:text-left lg:w-[50vw] flex flex-col gap-2">
    <div className="text-md lg:text-lg xl:text-2xl font-semibold text-[#3d74cdff]">
      {props.title}
    </div>
    <div className="text-sm xl:text-md">{props.description}</div>
    <div className="flex justify-center lg:justify-start my-4">
      {props.action}
    </div>
  </div>
);

const CardCTA = (props) => (
  <Link
    to={props.to}
    className="bg-[#3d74cdff] text-white rounded-md shadow-lg text-sm font-semibold px-4 py-2 flex gap-3 items-center justify-center w-max hover:translate-y-[-10%] duration-300"
  >
    <i className={`bi bi-${props.icon} text-2xl`}></i>
    <span>{props.text}</span>
  </Link>
);

const EmptySpace = () => <div className="h-[24px] lg:h-[96px]"></div>;

export function Home() {
  useEffect(() => {
    document.body.style.overflowY = "auto";
  });
  return (
    <>
      <Navbar />
      <EmptySpace />
      <Card
        mobile="flex-col"
        children={
          <>
            <CardImage src={img_route} />
            <CardParagraph
              title="Intelligent Route Planning Tool for Efficient Travel"
              description={
                <>
                  Simplify your travel plans with our Intelligent Route Finder
                  tool. Designed for ease of use, this tool lets you input up to
                  four ZIP codes and choose a business type, eliminating the
                  stress of manual route planning. Once you've set your
                  preferences, the tool efficiently calculates the best route,
                  presenting a curated list of destinations for your journey.
                  Explore each leg visually with interactive maps, ensuring a
                  smooth and optimized travel experience. Bid farewell to the
                  complexities of planning and welcome a stress-free way to
                  navigate your next adventure!
                </>
              }
              action={
                <CardCTA
                  to="/route"
                  text="Discover Your Route Now"
                  icon="sign-turn-slight-left"
                />
              }
            />
          </>
        }
      />
      <EmptySpace />
      <Card
        mobile="flex-col-reverse"
        children={
          <>
            <CardParagraph
              title="AI-Powered Social Media Post Generator"
              description={
                <>
                  Elevate your social media game effortlessly with our AI Post
                  Generator. This cutting-edge tool empowers you to create
                  captivating social media posts instantly. The user-friendly
                  interface allows you to specify the word count, platform
                  (Facebook, Twitter, Instagram, or LinkedIn), and the topic of
                  your post. Upon submission, our AI seamlessly processes your
                  request, delivering a professionally crafted post along with
                  an accompanying image. Effortlessly download the image, copy
                  the text, or generate another post—all at the click of a
                  button. Unleash the power of AI for engaging and impactful
                  social media content.
                </>
              }
              action={
                <CardCTA
                  to="/post"
                  text="Create Your Post Now"
                  icon="file-post"
                />
              }
            />
            <CardImage src={img_post} />
          </>
        }
      />
    </>
  );
}
